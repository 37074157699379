import focusWithin from 'focus-within';
import { $, $$ } from './helper/utils';
import outline from './helper/outline';
import { MOBILMEDIA } from './helper/constants';

focusWithin(document);
outline();

const sliderMap = new Map();
const sliderDragging = new Map();

export default function () {
  const mobileNavWrapper = $('.mobile-nav-wrapper');
  let mobileNavLoaded = false;

  if (MOBILMEDIA.matches && mobileNavWrapper !== null) {
    import('./components/mobileNav.js')
      .then((mobileNav) => {
        mobileNavLoaded = true;
        mobileNav.default();
      })
      .catch((err) => {
        console.warn('Chunk mobileNav.js loading failed', err);
      });
  }

  MOBILMEDIA.addListener((e) => {
    if (e.matches && mobileNavWrapper !== null && mobileNavLoaded === false) {
      import('./components/mobileNav.js')
        .then((mobileNav) => {
          mobileNavLoaded = true;
          mobileNav.default();
        })
        .catch((err) => {
          console.warn('Chunk mobileNav.js loading failed', err);
        });
    }
  });

  if ($('.slider') !== null) {
    import('./components/slider.js')
      .then(slider => slider.default())
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.home') !== null) {
    import('./components/slider.js')
      .then(slider => slider.home())
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  const cardModals = $$('.card-modal-content');
  if (cardModals.length > 0) {
    import('./components/cardsWithModal.js')
      .then(cardsWithModal => cardsWithModal.default())
      .catch((err) => {
        console.warn('Chunk cardsWithModal.js loading failed', err);
      });
  }
  
  if ($('.lightbox') !== null) {
    import('./components/lightbox.js')
      .then(lightbox => lightbox.default('.lightbox', sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  }

  if ($('.filter-search') !== null) {
    import('./pages/projects.js')
      .then(projects => projects.default('.filter-search'))
      .catch((err) => {
        console.warn(`Chunk projects.js loading failed: ${err}`);
      });
  }

  if ($('.nav-tabs') !== null || $('.accordion') !== null) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
}
